<template>
  <div>
    <!-- Clear Selected search criteria,  Selected Search Criteria-->
    <vx-card class="mb-8">
      <div class="card-title more-card-title flex justify-between items-center">
        <div class="flex items-center flex-wrap">
          <h2 class="w-auto mb-2">{{ LabelConstant.headingLabelSelectedCriteria }}</h2>
          <p class="table-data-entries-details text-left mb-2 w100">
            Total Suppressed Email Addresses: <span>{{ totalSuppression | tableRecordNumberFormatter }}</span>
          </p>
        </div>
        <div class="flex items-center flex-wrap">
        <vs-button
          color="success"
          class="sm:mt-0 mt-2 mr-2"
          icon-pack="feather"
          type="filled"
          @click="isUploadPopup = true"
        >Upload List</vs-button>
        <!-- Clear Selected search criteria and suppression -->
        <vs-button
          color="grey"
          type="border"
          class="grey-btn sm:mt-0 mt-2"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          @click="clearSearchCriteria()"
        >{{ LabelConstant.buttonLabelClearSearchCriteria }}</vs-button>
      </div>
      </div>
      <!-- Selected Search Criteria -->
      <div class="vx-row find-people">
        <div class="vx-col w-full">
          <input-tag v-model="tags" @input="testUpdateSelectedTags"></input-tag>
        </div>
      </div>
    </vx-card>
    
    <!-- Search Criteria Filter, suppression Table -->
    <vx-card>
      <div class="vx-row find-people search-lead">
        <!-- Search Criteria Filter -->
        <div class="vx-col xl:w-1/4 col-cus-12 mb-8 w-full">
          <div class="px-0 search-filters">
            <div slot="header" class="filter-header pl-0">{{ LabelConstant.headingLabelSearchCriteria }}</div>       
            <!-- Personal criteria  --> 
            <div class="filter-card">   
              <div class="filter-list">
                <vs-checkbox v-model="showEmailAddress" @click="emailAddress=null">Email Address</vs-checkbox>
                <div v-if="showEmailAddress">
                  <vs-input
                    v-model="emailAddress"
                    class="w-full"
                    placeholder="Type email address here"
                  />
                </div>
                <vs-checkbox v-model="showHashedAddress" @click="hashedAddress=null">Hashed Address</vs-checkbox>
                <div v-if="showHashedAddress">
                  <vs-input
                    v-model="hashedAddress"
                    class="w-full"
                    placeholder="Type hashed address here"
                  />
                </div>
                <div>
                  <vs-checkbox v-model="showDatePicker" @click="showDatePicker ? removeDate() : populateData()">Date Added</vs-checkbox>
                  <date-range-picker
                    v-show="showDatePicker"
                    ref="lastVisitedDynamicType"
                    class="float-unset"
                    :fromDateValue="fromDate"
                    :toDateValue="toDate"
                    @inputFromDate="(newFromDate) => {fromDate = newFromDate}"
                    @inputToDate="(newToDate) => {toDate = newToDate}"
                  ></date-range-picker>
                </div>
              </div>
            </div>  
          </div>
          <!-- buttons -->
          <div class="pt-5 mt-5 flex">
            <vs-button
              :title="LabelConstant.buttonLabelSearch"
              color="primary"
              type="filled"
              class="mr-1 px-5"
              @click="searchSuppression()"
            >{{ LabelConstant.buttonLabelSearch }}</vs-button>
          </div>
        </div>
        <!-- searched-suppression table-->
        <div class="vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left">
          <div>
            <vs-navbar class="nabarx mb-8 table-nav">
              <div slot="title">
                <vs-navbar-title> Search Suppression List </vs-navbar-title>
              </div>
              <vs-navbar-item index="0" class="search-associate">
                <dropdown-menu class="dropdown-export">
                  <button slot="trigger" class="dropdown-export-btn">
                    Actions
                    <i class="vs-icon notranslate icon-scale material-icons null">expand_more</i>
                  </button>
                  <ul slot="body">
                    <li @click="isPopup = true"> Add Email Address </li>
                    <li @click="isUploadPopup = true"> Upload List </li>
                    <li @click="resetConfirmPopup = true"> Reset List </li>
                    <li @click="selectedMain.length > 0 ? deleteConfirmPopup = true : ''"> Delete Email Addresses </li>
                  </ul>
                </dropdown-menu>
              </vs-navbar-item>
              <v-select
                class="ml-2 show-tbl-data"
                label="value"
                :options="showEntriesOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                v-model="recordPerMainPage"
                @input="searchSuppressionPerPage()"
              />
            </vs-navbar>
          </div>
          <!-- Find suppression table-->
          <div class="common-table pt-0">
            <!-- table -->
            <vs-table multiple v-model="selectedMain" :data="suppressionList" class="ecommerce-search-table">
              <template slot="thead">
                <vs-th>Email Address</vs-th>
                <vs-th>Date Added</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].EmailAddress">
                    <a class="cursor-pointer" @click="isPopup = true, editableSuppression = data[indextr], addSuppression = data[indextr].EmailAddress">{{ data[indextr].EmailAddress }}</a> 
                  </vs-td>
                  <vs-td :data="data[indextr].DateAdded">
                    {{ data[indextr].DateAdded }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- Total suppression and Pagination -->
            <div class="vx-row clear-both">
              <div class="vx-col w-full mt-4 lg:w-full">
                <div class="flex items-center justify-between paginate-row flex-wrap">
                  <p class="table-data-entries-details">
                    Email Addresses Found: <span>{{ totalSearchedSuppression | tableRecordNumberFormatter }}</span>
                  </p>
                  <b-pagination
                    pills
                    size="sm"
                    class="pr-3 bootstrap-pagination"
                    v-model="currentPage"
                    :total-rows="totalSuppression"
                    :per-page="recordPerMainPage"
                    :limit="1"
                    prev-text="< Prev"
                    next-text="Next >"
                    :hide-goto-end-buttons="true"
                    @change="searchSuppressionPerPage()"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- Delete -->
    <vs-popup class="transaction-popup" :active.sync="deleteConfirmPopup">             
        <div class="transaction-details">
            <div class="popup-icon">
                <img src="../../../assets/images/trash.svg">
            </div>
            <h2>Delete</h2>
            <p>Are you sure you wish to delete selected suppression(s)?</p>
            <div class="float-left mt-6 w-full text-center">             
                <vs-button @click="deleteSuppression()" color="success" class="mt-0 sm:w-auto w-full" type="filled">Delete</vs-button>
                <vs-button @click="deleteConfirmPopup = false, selectedMain = []" color="grey" type="border" class="grey-btn sm:ml-4  ml-0 mt-2 sm:mt-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>            
            </div>
        </div>            
    </vs-popup>
    <!-- Add -->
    <vs-popup class="" :title="editableSuppression ? 'Update Address to Suppress' : 'Add Addresses to Suppress'" :active.sync="isPopup">
      <template>
          <div class="vx-row">
            <div class="vx-col mb-2 w-full">
              <p v-if="!editableSuppression" class="w-full float-left"> Please enter email address or SHA 256 Hash, one per line </p>
              <p v-else>Email or SHA256 Hash</p>
              <div class="vx-row align-center">
                <div class="vx-col mb-0 w-full">
                  <vs-textarea v-if="!editableSuppression" class="w-full mb-4" v-model="addSuppression"> </vs-textarea>
                  <vs-input v-else class="w-full mb-4" type="text" v-model="addSuppression"></vs-input>
                </div>
              </div>
            </div>
          </div>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button color="danger" type="filled" class="mr-2" v-if="editableSuppression" @click="deleteConfirmPopup = true"> Delete </vs-button>
        <vs-button @click="!editableSuppression ? addToSuppression() : updateSuppression()" color="primary" type="filled" class="mr-2" :disabled="!addSuppression">{{ editableSuppression ? 'Update Address' : 'Add Addresses' }}</vs-button>
        <vs-button
          color="grey"
          type="border"
          class="grey-btn"
          @click="isPopup = false, addSuppression = null, editableSuppression = null"
        >{{ LabelConstant.buttonLabelClose }}</vs-button>
      </div>
    </vs-popup>
    <!-- Reset -->
    <vs-popup class="transaction-popup" :active.sync="resetConfirmPopup">             
        <div class="transaction-details">
            <div class="popup-icon">
                <img src="../../../assets/images/warning.svg">
            </div>
            <h2>Reset</h2>
            <p>Are you sure you want to reset your existing list?</p>
            <div class="float-left mt-6 w-full text-center">             
                <vs-button @click="resetExistingList()" color="success" class="mt-0 sm:w-auto w-full" type="filled">Reset</vs-button>
                <vs-button @click="resetConfirmPopup = false" color="grey" type="border" class="grey-btn sm:ml-4  ml-0 mt-2 sm:mt-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>            
            </div>
        </div>            
    </vs-popup>
    <!-- Is Overwrite Existing List -->
    <vs-popup class="transaction-popup" :active.sync="isOverwriteExistingListPopup">             
        <div class="transaction-details">
            <div class="popup-icon">
                <img src="../../../assets/images/warning.svg">
            </div>
            <h2>Overwrite Existing List</h2>
            <p>Selecting this option will overwrite your existing list. Do you wish to continue?</p>
            <div class="float-left mt-6 w-full text-center">             
                <vs-button @click="isOverwriteExistingListPopup = false" color="success" class="mt-0 sm:w-auto w-full" type="filled">Yes</vs-button>
                <vs-button @click="isOverwriteExistingListPopup = false, overwriteExistingList = !overwriteExistingList" color="grey" type="border" class="grey-btn sm:ml-4  ml-0 mt-2 sm:mt-0 sm:w-auto w-full">No</vs-button>            
            </div>
        </div>            
    </vs-popup>
    <!-- Upload -->
    <vs-popup class="popup-width" title="Upload Suppression List" :active.sync="isUploadPopup">
      <template>
          <div class="vx-row">
            <div class="vx-col mb-2 w-full">
              <div class="vx-row align-center">
                <div class="vx-col mb-3 w-full">
                  <vs-checkbox v-model="overwriteExistingList" @click="isOverwriteExistingListPopup = !overwriteExistingList"> Overwrite Existing List</vs-checkbox>
                </div>
                <div class="vx-col flex justify-between flex-wrap w-full mt-5">
                  <h5 class="text-primary" v-if="!csvFileName && !csvFileErrorMessage"></h5>
                  <span class="file-name" v-if="csvFileName">{{ csvFileName }}</span>
                  <span class="file-name text-danger" v-if="csvFileErrorMessage">{{ csvFileErrorMessage }}</span>
                  <a href="/sample-exclude-email-list.csv" download class="text-danger cursor-pointer"> Download Sample File</a>
                </div>
                <div class="vx-col w-full mt-5">
                  <div class="upload-img flex flex-wrap w-full">
                    <div class="upload-input">
                      <feather-icon icon="UploadCloudIcon" class="primary-c"></feather-icon>
                      <span class="file-text">Upload File</span>
                      <input type="file" ref="file" name="file" @change="loadCSV($event)"/>
                    </div>
                    <!-- <div class="vx-row ml-3 w-full">
                      <span class="text-danger text-sm" v-if="importContactFileError" >{{ importContactFileError }}</span>
                    </div> -->
                    <div class="vx-row ml-3 w-full">  
                      <span>Drag a file containing a list of email addresses to upload. Each address should in a separate line. Maximum file size is 100,000 records.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button title="Upload" color="primary" type="filled" class="mr-2" :disabled="!isImportButton" @click="uploadEmailList()">Upload</vs-button>
        <vs-button
          color="grey"
          type="border"
          class="grey-btn"
          @click="cancelUploadEmailList()"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import InputTag from "vue-input-tag";
import vSelect from "vue-select";
import { BPagination } from "bootstrap-vue";
import DropdownMenu from "v-dropdown-menu";
import "v-dropdown-menu/dist/v-dropdown-menu.css";
import DateRangePicker from "../../../components/filter/DateRangePicker";
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

export default {
  components: {
    InputTag,
    "v-select": vSelect,
    BPagination,
    DropdownMenu,
    DateRangePicker,
  },
  data: () => ({     
    // for store all selected search criteria
    tagsTempArr: [],

    // Data
    fromDate: "01/01/2022",
    toDate: "01/01/2022",
    fromDateTemp: "",
    toDateTemp: "",
    showDatePicker: false,

    showEmailAddress: false,
    emailAddress: null,

    showHashedAddress: false,
    hashedAddress: null,

    totalSearchedSuppression: 0,
    
    // page
    totalSuppression: 0,
    recordPerMainPage: 10,
    currentPage: 1,
    showEntriesOptions: [
      { key: 10, value: 10 },
      { key: 25, value: 25 },
      { key: 50, value: 50 },
      { key: 100, value: 100 },
    ],
    
    // suppression list for table
    suppressionList: [],
    searchParams: null,
    selectedMain: [],

    isPopup: false,
    addSuppression: null,
    resetConfirmPopup: false,
    deleteConfirmPopup: false,
    editableSuppression: null,

    isUploadPopup: false,
    isOverwriteExistingListPopup: false,
    overwriteExistingList: false,
    csv: null,
    isImportButton: false,
    csvFileName: null,
    csvFileErrorMessage: null,
    csvUploadRecords: [],
  }),
  computed: {
    tags: {
      get: function () {
        let arr = [];
        let that = this;
        //Search Criteria Input       
        if(!arr.includes(this.fromDate) &&!arr.includes(this.toDate) && this.fromDate!="" && this.toDate!=""){
          arr.push("Date:"+this.fromDate+'-'+this.toDate);
        }
        if (!arr.includes(this.emailAddress) && this.emailAddress!=null && this.emailAddress!="") {
          arr.push("Email:" + this.emailAddress);
        }
        if (!arr.includes(this.hashedAddress) && this.hashedAddress!=null && this.hashedAddress!="") {
          arr.push("Hashed:" + this.hashedAddress);
        }
        
        that.tagsTempArr = [];
        that.tagsTempArr = arr;
        return arr;
      },
      set: function () {},
    },
  },
  created() { 
    // this.showDatePicker = true;
    this.setFromDate();
    this.setToDate();
    setTimeout(() => {
      this.removeDate();
      this.searchSuppression();
    }, 100);
  },
  mounted(){
    this.searchSuppressionCountTotal();
  },
  methods: {
    formattedDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss.SSS");
    },
    removeDate(){
      this.fromDateTemp = this.fromDate;
      this.toDateTemp = this.toDate;
      this.fromDate = "";
      this.toDate = "";
    },
    populateData(){
      this.fromDate = this.fromDateTemp;
      this.toDate = this.toDateTemp;
      this.fromDateTemp = "";
      this.toDateTemp = "";
    },

    // change in Selected Criteria Input
    testUpdateSelectedTags(value) {
      let keyArr = this.tagsTempArr.filter((x) => value.indexOf(x) === -1);
      let testStr = keyArr[0];

      if (testStr.includes("Date:")) {
        this.fromDate=null
        this.toDate=null
        this.setFromDate();
        this.setToDate();
      }
      if (testStr.includes("Email:")) {
        this.emailAddress = null;
      }
      if (testStr.includes("Hashed:")) {
        this.hashedAddress = null;
      }
    },
    // clear search criteria
    clearSearchCriteria() {
      this.showEmailAddress= false;
      this.showHashedAddress=false;
    
      this.emailAddress= null;
      this.hashedAddress = null;

    },
    // search suppression params
    searchSuppressionParam(){
      // Data
      let isDateLastVisitedSelected;
      if(this.fromDate == "" && this.toDate == ""){
        isDateLastVisitedSelected=false;
      }else{
        isDateLastVisitedSelected=true;
      }
      let lastVisitedDateFrom= "2000-01-01T00:00:00.000Z";
      let lastVisitedDateTo= "2000-01-01T00:00:00.000Z";
      let dateLastVisitedDynamicType=this.checkDateRanges(this.fromDate,this.toDate);
      if(dateLastVisitedDynamicType=='custom range'){
        dateLastVisitedDynamicType=null;
        lastVisitedDateFrom= this.fromDate != "" ? this.formattedDate(this.fromDate) : this.formattedDate(this.fromDateTemp);
        lastVisitedDateTo= this.toDate != "" ? this.formattedDate(this.toDate) : this.formattedDate(this.toDateTemp);
      }    

      let isEmailAddressSelected = false;
      let emailAddressParam=null;
      if (this.emailAddress){
        isEmailAddressSelected = true;
        emailAddressParam=this.emailAddress
      }

      let isHashedAddressSelected = false;
      let hashedAddressParam=null;
      if (this.hashedAddress){
        isHashedAddressSelected = true;
        hashedAddressParam=this.hashedAddress
      }

      this.searchParams={
        Page: this.currentPage,
        PageSize: this.recordPerMainPage,
        IsDateAddedSelected: isDateLastVisitedSelected,
        DateAddedDateFrom: lastVisitedDateFrom,
        DateAddedDateTo: lastVisitedDateTo,
        DateDateAddedDynamicType: dateLastVisitedDynamicType,
        IsEmailAddressSelected: isEmailAddressSelected,
        EmailAddress: emailAddressParam,
        IsHashedAddressSelected: isHashedAddressSelected,
        HashedAddress: hashedAddressParam
      }
    },
    // search suppression 
    searchSuppression(){
      this.suppressionList=[]
      this.currentPage=1
      this.searchSuppressionParam();

      this.$vs.loading();
      this.axios.post("/ws/ConfigExcludeEmailList/SearchSuppressionList",this.searchParams)
        .then((response) => {
          let data = response.data;
          this.suppressionList=data.SuppressionList?data.SuppressionList:[]; 
          this.searchSuppressionCount(this.searchParams);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //search suppression by page
    searchSuppressionPerPage(){
      setTimeout(() => {
        this.searchParams.Page=this.currentPage;
        this.searchParams.PageSize=this.recordPerMainPage;
        if(this.totalSuppression >= 10){
          this.axios.post("/ws/ConfigExcludeEmailList/SearchSuppressionList",this.searchParams)
            .then((response) => {
              let data = response.data;
              this.suppressionList=data.SuppressionList?data.SuppressionList:[];  
            })
        }
       }, 1000); 
    },
    // DateLastVisitedDynamicType Value
    checkDateRanges(start,end){
      let selectedStart = start;
      let selectedEnd = end;
      let dateRanges =this.$refs.lastVisitedDynamicType ? this.$refs.lastVisitedDynamicType.$refs.picker.ranges : {};
      if((moment(dateRanges['This month'][0]).format("MM/DD/YYYY") == selectedStart) && moment(dateRanges['This month'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "this month";
      } else if((moment(dateRanges['Last month'][0]).format("MM/DD/YYYY") == selectedStart) && moment(dateRanges['Last month'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "last month";
      } else if(moment(dateRanges['This year'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['This year'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "this year";
      } else if(moment(dateRanges['Today'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['Today'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "today";
      } else if(moment(dateRanges['Yesterday'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['Yesterday'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "yesterday";
      } else {
        return "custom range";
      }
    },
    // Total searched known customr form the search
    searchSuppressionCount(searchParams){
      this.axios.post("/ws/ConfigExcludeEmailList/GetSearchSuppressionListCount",searchParams)
        .then((response)=>{
          let data=response.data;
          this.totalSearchedSuppression=data;
        })
    },
    // Total known customr
    searchSuppressionCountTotal(){
      this.axios.get("/ws/ConfigExcludeEmailList/GetTotalSuppressionList")
        .then((response)=>{
          let data=response.data;
          this.totalSuppression=data;
        })
    },
    addToSuppression(){
      let payload = this.addSuppression.split("\n");

      this.$vs.loading();
        this.axios
            .post("/ws/ConfigExcludeEmailList/AppendToSuppressionList",payload)
            .then(() => {
              this.isPopup = false,
              this.addSuppression = null;
              this.searchSuppression();
              this.$vs.notify({
                title: "Success",
                text: "Email Addresses added successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                  title: "Error",
                  text: e.response.data.Errors[0].Message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  position: "top-right",
                  time: 4000
                });
            });  
    },
    updateSuppression(){
      let payload = {
        SuppressionListId: this.editableSuppression.SuppressionListId,
        EmailAddress: this.addSuppression,
        DateAdded: this.editableSuppression.DateAdded,
        DateLastUpdated: this.editableSuppression.DateLastUpdated
      }
      this.$vs.loading()
        this.axios.post("/ws/ConfigExcludeEmailList/UpdateSuppression",payload).then(() => {
          this.isPopup = false;
          this.editableSuppression = null;
          this.addSuppression = null;
          this.searchSuppression()
          this.$vs.notify({
                    title:'Success',
                    text: 'Email updated successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
            this.$vs.loading.close()
        }).catch(e => {
            this.showError(e);
            this.$vs.notify({
                title:'Error',
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: 'feather',
                icon:'icon-alert-circle',
                position:"top-right",
                time:4000
            });
        });  
    },
    resetExistingList(){
      this.$vs.loading();
        this.axios
            .get("/ws/ConfigExcludeEmailList/ResetSuppressionList")
            .then(() => {
              this.resetConfirmPopup = false
              this.searchSuppression()
              this.searchSuppressionCountTotal();
              this.$vs.notify({
                title: "Success",
                text: "Existing list reset successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                  title: "Error",
                  text: e.response.data.Errors[0].Message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  position: "top-right",
                  time: 4000
                });
            });
    },
    deleteSuppression(){
      let payload = []
      for (let i = 0; i < this.selectedMain.length; i++) {
        payload.push(this.selectedMain[i].SuppressionListId)
      }
      this.$vs.loading()
        this.axios.post("/ws/ConfigExcludeEmailList/DeleteSuppressions",payload).then(() => {
            this.searchSuppression()
            this.searchSuppressionCountTotal();
            this.deleteConfirmPopup = false;
            this.isPopup = false;
            this.selectedMain = [];
            this.$vs.notify({
                title:'Success',
                text: 'Email deleted successfully.',
                color: "success",
                iconPack: 'feather',
                icon:'icon-check',
                position: "top-right",
                time:4000
            });
            this.$vs.loading.close()
        }).catch(e => {
            this.showError(e);
            this.$vs.notify({
                title:'Error',
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: 'feather',
                icon:'icon-alert-circle',
                position:"top-right",
                time:4000
            });
        });
    },

    cancelUploadEmailList(){
      this.$refs.file.value = "";
      this.isUploadPopup = false;
      this.isOverwriteExistingListPopup = false;
      this.overwriteExistingList = false;
      this.csv = null;
      this.isImportButton = false;
      this.csvFileName = null;
      this.csvFileErrorMessage = null;
      this.csvUploadRecords = [];
    },
    // Load CSv File
    loadCSV(e) {
      if (this.$refs.file.files[0].type == "text/csv" || this.$refs.file.files[0].type == "application/vnd.ms-excel") {
        this.isImportButton = true;
        this.csvFileName = this.$refs.file.files[0].name;
        this.csvFileErrorMessage = null;
      } else {
        this.csvFileErrorMessage = "Please upload CSV file only.";
        this.terminateRecordUpload();
        return false;
      }
      var vm = this;
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.onload = function (event) {
          var csv = event.target.result;
          vm.csv = vm.csvJSON(csv);
        };
      } else {
        alert("File Reader are not supported in this browser!");
      }
    },
    terminateRecordUpload(){
      this.isImportButton = false;
      this.$refs.file.value = "";
      this.csvFileName = null;
    },
    csvJSON(csv){
      // var vm = this;
      var lines;
      if(csv.includes("\r\n")){
        lines = csv.split("\r\n");
      } else {
        lines = csv.split("\n");
      }

      if(lines[0].split(",").length != 1){
        this.csvFileErrorMessage = "Please enter data as per sample file."
        this.terminateRecordUpload();
        return false;
      }

      if(lines.length > 100002){
        this.csvFileErrorMessage = "You can upload maximum 100,000 records at a time."
        this.terminateRecordUpload();
        return false;
      }
      // console.log(lines)
      this.csvUploadRecords = lines;
    },
    uploadEmailList(){
      let payload={
        OverwriteList: this.overwriteExistingList,
        IsHashed: false,
        AddressList: this.csvUploadRecords.slice(0, -1)
      }
      this.$vs.loading()
        this.axios.post("/ws/ConfigExcludeEmailList/UploadSuppressionList", payload).then(() => {
          this.searchSuppression()
          this.searchSuppressionCountTotal();
          this.cancelUploadEmailList()
            this.$vs.notify({
                title:'Success',
                text: 'Email list uploaded successfully.',
                color: "success",
                iconPack: 'feather',
                icon:'icon-check',
                position: "top-right",
                time:4000
            });
            this.$vs.loading.close()
        }).catch(e => {
            this.showError(e);
            this.$vs.notify({
                title:'Error',
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: 'feather',
                icon:'icon-alert-circle',
                position:"top-right",
                time:4000
            });
        });  
    }
  },
};
</script>